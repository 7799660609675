import { AppTheme } from '@core/theme/types/main';
import { appCreateStyles } from '@core/theme/utils/create-styles';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles({ spacing, breakpoints }: AppTheme) {
  return appCreateStyles({
    container: {
      display: 'flex',
      width: '320px',
      height: '34px',
    },
    switchBox: {
      display: 'flex',
      width: '100%',
    },
    switch: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '18px',
      background: '#1E1D36',
      [breakpoints.up(Layout.laptop)]: {
        background: '#412D68',
      },

      '& + &': {
        marginLeft: spacing(12),
      },
    },
    dropdownBox: {
      display: 'none',

      [breakpoints.down(Layout.mobile)]: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
      },
    },
    dropdown: {
      width: '100%',
      padding: '6px 16px',
      textTransform: 'uppercase',
      backgroundColor: _var(CSS_VARIABLES.backgroundMainColor),
      border: 'none',
      color: _var(CSS_VARIABLES.accentColor),

      '&:hover': {
        border: 'none',
        backgroundColor: _var(CSS_VARIABLES.backgroundMainColor),
      },
    },
  });
}
